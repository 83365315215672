.services__container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
}

.services__card {
    text-align: center;
    padding: 1.875rem;
    border-radius: var(--border-radius);
    background-color: var(--container-color);
    transition: all 0.3s ease-in-out;
}

.services__card:hover {
    transform: translateY(-10px);
}

.services__card:nth-child(1) {
    background-color: rgb(35, 71, 43);
    box-shadow: 0px 5px 20px 0px rgb(108,108,229 / 50%);
}

.services__card:nth-child(2) {
    background-color: rgb(95, 95, 92);
    box-shadow: 0px 5px 20px 0px rgb(249,215,76 / 50%);
}

.services__card:nth-child(3) {
    background-color: rgb(223, 88, 106);
    box-shadow: 0px 5px 20px 0px rgb(249,123,139 / 50%);
}

.services__img {
    margin-bottom: 1.25rem;
}

.services__title {
    font-size: var(--h3-font-size);
    margin-bottom: 1rem;
    color: #fff;
}

.services__description {
    color: #f8f9fa;
}



@media screen and (max-width: 1024px) {
    .services__container {
      grid-template-columns: repeat(2, 330px);
      justify-content: center;
      row-gap: 1.875rem;
    }
}

@media screen and (max-width: 768px) {
    .services__container {
      grid-template-columns: 310px;
    }
}

@media screen and (max-width: 350px) {
    .services__container {
      grid-template-columns: 1fr;
    }
}
